var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "admin-header",
      class: { "bg-image": _vm.background },
      style: _vm.background
        ? { backgroundImage: "url(" + _vm.background + ")" }
        : false
    },
    [
      _c("div", { staticClass: "container py-5 sm-down:text-center" }, [
        _c("h1", { staticClass: "mb-0 d-block" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "line-height": "1.2" } },
          [_vm._t("default")],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }